@import url('./variables.less');
@import url('assets/fonts/fa5/v4-shims.css');
@import url('assets/fonts/fa5/fontawesome.min.css');
@import url('assets/fonts/fa5/solid.min.css');
@import url('assets/fonts/fa5/brands.min.css');

@font-face {
  font-family: 'Manus';
  font-display: swap;
  src: url('assets/fonts/35260B_1_0.eot');
  src: url('assets/fonts/35260B_1_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/35260B_1_0.woff2') format('woff2'),
    url('assets/fonts/35260B_1_0.woff') format('woff'),
    url('assets/fonts/35260B_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Replica';
  font-display: swap;
  src: url('assets/fonts/Replica-LL/ReplicaLL-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Replica Mono';
  font-display: swap;
  src: url('assets/fonts/Replica-Mono/ReplicaMonoLL-Regular.otf') format('opentype');
}

// regular style toast
@import '~ngx-toastr/toastr';

/* Main CSS 
-------------------------------------------------- */

html {
  position: relative;
  min-height: 100%;
  background: @black; // needed for color bellow footer
}

body {
  line-height: 1.4;
}

/* Generic Style Elements
-------------------------------------------------- */

// main text font
* {
  font-family: 'Replica', sans-serif;
  font-display: optional;
}

// backgrounds
.bg-grey {
  background-color: @tertiary;
}

// backgrounds
.bg-beige {
  background-color: @secondary;
}

.bg-nightgreen {
  background-color: @primary;
}

// grey text
.text-secondary {
  color: #868e96 !important;
}

a {
  text-decoration: none !important;
  color: @quaternary;
  transition: 0.25s !important;

  &:hover {
    opacity: 0.8;
    color: @quaternary;
  }
}

b {
  letter-spacing: 0.5px;
}

.text-small,
small,
.small {
  font-size: 80%;
  line-height: 1.2;
  opacity: 0.8;
}

.text-big {
  font-size: 140%;
  line-height: 1.1;
}

.text-xs {
  font-size: 76%;
  line-height: 1.2;
  opacity: 0.6;
}

p.quote {
  position: relative;

  i.fa-quote-right {
    position: absolute;
    font-size: 80px;
    opacity: 0.1;
    top: -10px;
    left: -10px;
  }

  span {
    font-size: 1.15rem;
    line-height: 1;
    opacity: 0.7;
  }

  .author {
    opacity: 1;
    font-size: 1rem;
  }
}

.vh-50 {
  min-height: 50vh !important;
}

.navbar.navbar-subheader {
  background-color: @secondary;
  position: fixed;
  top: 69px;
  z-index: 9999;
  width: 100%;

  .nav-link {
    font-size: 16px;
    padding: 4px 0;
    color: @white;
    cursor: pointer;
    line-height: 1.2;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 990px) {
    top: 64px;
  }
}

/* BADGES */

.badge {
  padding: 0.55em 0.8em 0.35em 0.8em;
  font-size: 0.85em;
  font-weight: 500;
  border-radius: 0.85rem;
}

// badge success
.badge-success {
  background-color: @success;
}

.badge.single {
  background-color: @quinary;
}

.badge.double {
  background-color: @primary;
}

.badge.mattresses {
  background-color: @darkgrey;
}

.badge.multi {
  background-color: @secondary;
}

.badge-ta {
  border-radius: 0;
  background-color: @secondary;
  font-weight: 400;
  margin: 0 5px 5px 0;
  font-size: 14px;
}

.alert {
  border-radius: 0;
  font-weight: 400;
  padding: 12px 12px 10px 15px;
  display: flex;
  line-height: 1.1;

  i {
    flex-shrink: 0;
    margin-right: 5px;
    top: -2px;
    position: relative;
  }

  h3 {
    color: @white;
    font-size: 20px;
    display: inline-block;
    margin-bottom: 15px;
    padding-left: 10px;
  }
}

.alert-nightgreen {
  background-color: @primary;
  color: @white;
}

.alert-info {
  background-color: @tertiary;
  border-color: transparent;

  h3 {
    color: @primary;
  }

  p {
    color: @black;
  }
}

.alert-grey {
  background-color: #f5f5f5;
  border-color: transparent;

  h3 {
    color: @primary;
  }

  p {
    color: @black;
  }
}

// content
.content {
  height: 100%;
  width: 100%;
  padding-top: 70px;
  min-height: 60vh;
  overflow-x: hidden;

  @media screen and (max-width: 998px) {
    padding-top: 60px;
  }
}

// content borders
.content-border-light,
.content-border-dark,
.content-border-grey {
  background-repeat: repeat-x;
  width: 100%;
  height: 33px;
  position: absolute;
  z-index: 90;
}

.content-border-bottom {
  bottom: 0px;
}

.content-border-top {
  //    top: -10px;
  top: 0px;
}

.content-border-rotate {
  bottom: -10px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  width: 100%;
}

// Standard Stylings

h2,
.h2 {
  line-height: 1.2;
  font-size: 2.2em;
  color: @primary;
}

h3,
.h3 {
  line-height: 1.2;
  font-size: 1.5rem;
  color: @primary;
}

.h4,
h4 {
  font-size: 1.15rem;
}

.text-gray {
  color: #999 !important;
}

.text-green {
  color: @success !important;
}

.text-nightgreen {
  color: @primary !important;
}

.text-beige {
  color: @secondary !important;
}

.text-orange {
  color: @quaternary;
}

.text-black {
  color: @black !important;
}

.text-white {
  color: @white !important;
}

.text-red {
  color: @danger !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-right {
  text-align: right !important;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn {
  border-radius: 50px;
  padding: 14px 27px;
  transition: 0.25s !important;
  line-height: 1.2;

  letter-spacing: 1px;

  &:hover {
    color: @white;
    opacity: 0.85;
  }
}

.btn-orange {
  background-color: @quaternary !important;
  border: none;
  color: @white;
  text-transform: uppercase;
}

.btn-outline-orange,
.btn-outline-white {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 25px;
  font-size: 0.8rem;
  cursor: pointer !important;

  &:hover {
    color: @white !important;
  }
}

.btn-outline-white {
  color: @white !important;
  border: 2px solid @white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.btn-outline-orange {
  color: @quaternary !important;
  border: 2px solid @quaternary;

  &:hover {
    background-color: @quaternary;
  }
}

.btn-small {
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.btn-xs {
  font-size: 0.9rem;
  padding: 12px 28px !important;
  line-height: 1.2;
  text-transform: none;
  letter-spacing: 0;
}

.btn-xl {
  border-radius: 50px;
  padding: 16px 45px;
  letter-spacing: 2px;

  @media screen and (max-width: 768px) {
    padding: 12px 30px;
    letter-spacing: 0;
  }
}

.btn-group-fixed {
  position: fixed;
  z-index: 9;
  right: -1px;
  top: 30%;

  button {
    border-radius: 0;
    background-color: @primary;
    border-color: @white;
    width: 50px !important;
    height: 50px;
    padding: 0;

    i {
      color: @white;
    }

    &:hover,
    &:active {
      background-color: @primary;
      border-color: @white;
      opacity: 0.75;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.circle-number {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 2px solid #00353b !important;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
  padding: 0 !important;
}

@media screen and (max-width: 1200px) {
  .circle-date {
    font-size: 14px;

    .circle-number {
      width: 26px;
      height: 26px;
      line-height: 23px;
      margin-right: 5px;
    }
  }
}

.action-link {
  cursor: pointer;
}

.toast-container {
  z-index: 99999999 !important;
}

.toast-success {
  background-color: @quinary;
}

.toast-error {
  background-color: @quaternary;
}

.toast-warning {
  background-color: @quaternary-light;
}

.toast-info {
  background-color: @secondary;
}

// TABLES

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: @tertiary;
  --bs-table-accent-bg: transparent;
}

.table> :not(caption)>*>* {
  background-color: transparent;
  box-shadow: none;
}

// FORMS

.input-group-text {
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 0;
}

form {
  .col-form-label {
    padding-top: 7px;
    padding-bottom: 0;
  }

  .form-group {
    line-height: 1;
    align-items: center;

    small.form-text {
      line-height: 1;
      margin-top: 4px;
      display: block;
    }
  }

  h2 {
    line-height: 1;
  }

  p {
    line-height: 1;
  }
}

.form-control,
select {
  font-size: 1rem;
  color: @black;
  background-color: @white;
  border: 1px solid #333;
  border-radius: 0;
  padding: 7px 10px 3px 10px;
  height: 35px;
  line-height: 1.2;

  &::placeholder {
    opacity: 0.7 !important;
  }
}

.form-control:focus {
  box-shadow: none;
}

.form-check-input {
  margin-top: 0;
}

input[type='radio'] {
  -webkit-appearance: none;
}

.input-underline {

  .form-control,
  select {
    border: none !important;
    border-bottom: 1px solid #333 !important;
    padding: 7px 0 3px 0;
  }
}

textarea {
  height: auto;
}

.icon-rounded {
  border-radius: 50%;
  padding: 9px 0;
  top: -5px;
  font-size: 12px;
  color: @white;
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;

  &.icon-rounded-nightgreen {
    background-color: @primary;
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 99999999;
}

.bs-datepicker-head {
  border-radius: 0;
  background-color: @quaternary !important;
}

.bs-datepicker-container {
  padding: 0;
}

// Validation Styles

form.is-submitted input.ng-invalid,
form.is-submitted select.ng-invalid,
form.is-submitted textarea.ng-invalid {
  background-image: url('./assets/icons/validation.svg');
  border-color: @danger !important;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.6em + 0.375rem) calc(0.6em + 0.375rem);
  background-color: @danger-background;
}

form.is-submitted input[type='checkbox'].ng-invalid {
  background-image: none;
  padding-right: 0;
  background-position: unset;
  background-size: unset;
}

form.is-submitted input[type='checkbox'].ng-invalid+p.small {
  color: @danger !important;
}

form.is-submitted .form-group-payment.invalid {
  border-color: @danger !important;
  background-color: @danger-background;
}

// Carousel Styles

carousel {
  z-index: 9 !important;
}

.carousel-arrows {
  .carousel-arrow-next {
    right: 30px !important;
    background-image: url(/assets/icons/arrow-right.svg) !important;
  }

  .carousel-arrow-prev {
    left: 30px !important;
    background-image: url(/assets/icons/arrow-left.svg) !important;
  }

  .carousel-arrow {
    background-color: transparent !important;
    background-size: 15px !important;
    box-shadow: none !important;
  }
}

.multiple-carousel {
  .carousel-arrows {
    .carousel-arrow-next {
      top: 50% !important;
      right: -50px !important;
      background-image: url(/assets/icons/arrow-right-black.svg) !important;
    }

    .carousel-arrow-prev {
      top: 50% !important;
      left: -50px !important;
      background-image: url(/assets/icons/arrow-left-black.svg) !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .multiple-carousel {
    .carousel-arrows {
      .carousel-arrow-next {
        right: 0px !important;
        background-image: url(/assets/icons/arrow-right.svg) !important;
      }

      .carousel-arrow-prev {
        left: 0px !important;
        background-image: url(/assets/icons/arrow-left.svg) !important;
      }
    }
  }
}

// content pages spacing
.content-spacing h1 {
  padding: 13px;
}

@media (min-width: 576px) {
  .content-spacing h1 {
    margin: auto;
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .content-spacing h1 {
    margin: auto;
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .content-spacing h1 {
    margin: auto;
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .content-spacing h1 {
    margin: auto;
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .content-spacing h1 {
    margin: auto;
    max-width: 1320px;
  }
}

// list styles

ul.list-orange,
#packageServicetimeprice ul {
  list-style: none;
  padding-left: 20px;

  li {
    padding-bottom: 10px;
    position: relative;

    &:before {
      content: '\002B';
      color: @quaternary;
      display: inline-block;
      padding-right: 0px;
      position: absolute;
      margin-top: -2px;
      left: -20px;
    }
  }
}

#packageServicetimeprice ul {
  padding-left: 20px;

  li {
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    ul {
      flex-basis: 100%;

      li {
        &:before {
          left: 20px !important;
          top: 5px !important;
        }
      }
    }

    &:before {
      position: absolute !important;
      top: 0 !important;
      left: -20px;
    }
  }
}

ul.list-orange-icon,
#packageServicetimeprice ul {
  li {
    &:before {
      content: '\f067';
      padding-right: 15px;
      margin-top: 3px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      font-size: 9px;
      position: absolute;
      left: -20px;
    }

    ul {
      li {
        padding: 5px 0 0 40px;
        opacity: 0.8;

        &:before {
          content: '\f068';
          position: relative;
        }
      }
    }
  }
}

.fa-plus.text-small.text-orange {
  padding-right: 5px;
  margin-top: 2px;
  font-size: 9px;
  margin-right: 20px;
}

ul.list-big {
  font-size: 1.3rem;

  li {
    &:before {
      content: '\f067';
      padding-right: 15px;
      margin-top: 4px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.modal-backdrop {
  z-index: 999999;
  background-color: @primary;

  &.show {
    opacity: 0.85 !important;
  }
}

.modal {
  z-index: 999999;

  .modal-dialog {
    max-width: 800px;
    margin-top: 70px;

    .modal-content {
      border-radius: 0;
    }

    .btn-close {
      color: @primary;
      opacity: 1;
    }

    .modal-header {
      border-bottom: none;

      h2 {
        font-size: 1.8em;
        width: 100%;

        span {
          font-size: 15px;
          display: block;
          line-height: 1;
        }
      }
    }

    .modal-footer {
      border-top: none;
      justify-content: center;
    }

    form .form-control,
    form select {
      width: 100%;
    }
  }
}

.price-card-content {

  p,
  b {
    font-weight: 400 !important;
  }

  b font {
    color: @quaternary !important;
  }

  hr {
    display: none;
  }
}

/* Card Styles */

.card {
  height: 350px;
  border-radius: 0;
  border: none;
  align-content: flex-end;
  justify-content: center;
  flex-flow: wrap;
  transition: all 2s;
  background-color: transparent;
  background-size: cover;
  overflow: hidden;

  .card-content {
    z-index: 9;
    position: absolute;
    bottom: -70px;
    transition: all 0.4s;

    .card-title {
      width: 100%;
      color: @white;
      font-size: 1.5rem;
      font-weight: 400;
      text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
      position: relative;
    }

    h2.card-title:after {
      content: '';
      height: 2.5px;
      width: 50px;
      background-color: @white;
      position: absolute;
      bottom: -10px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }

  &:hover {
    .corner-top-right {
      background-color: transparent;
      height: auto;
      transform: rotate(0deg);
      right: 0;
      left: 0;
      margin: 0 auto;
      top: 10px;
      width: 100%;
    }

    .card-content {
      bottom: 0;
    }
  }

  &:after {
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    transition: all 0.4s;
  }

  &:hover:after {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

.card-big {
  .card {
    height: 450px !important;
  }
}

.card-small {
  .card {
    height: 220px !important;
  }
}

// Tooltip

.tooltip {
  line-height: 1;

  .tooltip-inner {
    font-size: 90% !important;
    font-weight: normal;
    letter-spacing: 0.5px;
    padding: 6px 12px !important;
  }
}

// Accordion Styles

.accordion {
  .card {
    height: auto;
    align-content: unset;
    flex-flow: column;
    margin-bottom: 15px;

    .card-body {
      padding: 2rem 0;
    }

    &:after {
      width: 0;
      height: 0;
    }

    .card-header {
      padding: 0.5rem 0.6rem;
      background-color: @tertiary;
      border-bottom: none;
      text-align: center;
      border-radius: 0 !important;

      button {
        padding-left: 15px;

        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          padding: 4px 10px 0px 10px !important;
          letter-spacing: 0;
          font-size: 16px !important;

          &:after {
            padding-left: 7px !important;
            margin-top: 2px !important;
            font-size: 13px !important;
          }
        }
      }

      .btn-link,
      button {
        color: @primary;
        text-decoration: none;
        letter-spacing: 0 !important;
        font-size: 1.2rem;
        border-radius: 0;
        padding: 8px 5px 4px !important;

        &:after {
          content: '\f078';
          padding-left: 10px;
          margin-top: 4px;
          font-family: 'Font Awesome 5 Free';
          font-weight: 600;
          font-size: 15px;
        }
      }
    }
  }

  .panel-open {
    .card-header {

      .btn-link,
      button {
        &:after {
          content: '\f077';
        }
      }
    }

    img {
      width: 100%;
      max-width: 80px;
    }
  }
}

// Accordion green

.accordion.accordion-green {
  .card {
    .card-header {
      background-color: @primary;
      text-align: left;

      button {
        color: @white;
      }
    }
  }
}

// cta container
.cta {
  background-color: @success;
}

.header-small #mainImage {
  height: 350px !important;
  overflow: hidden !important;

  .claim-header {
    bottom: 90px !important;
  }
}

.ta-badge {
  width: 100%;
  max-width: 330px;
}

#mainImage {
  .claim-header {
    h1 {
      .subtitle {
        margin-top: 20px;
        color: @white;
        font-size: 1.1rem;
      }
    }
  }
}

// context headers (e.g. list pages headers, detail pages headers)
.context {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;

  h1,
  .h1 {
    color: @white;
    font-size: 4rem;
    line-height: 4rem;
    //        font-family: 'Manus';
    //        letter-spacing: 0.055em;
    text-transform: none;
    font-weight: bold;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
    color: @white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .description {
    color: @white;
    line-height: 1.2rem;
  }

  .list-group-item {
    border: 0px;
    padding: 0.025rem 0rem;
    margin-bottom: 0px;
    color: @white;
    background: transparent;
    font-size: 1.25rem;

    i {
      width: 30px;
      text-align: center;
      margin-right: 20px;
    }
  }

  img.img-header {
    position: absolute;
    top: -30%;
    left: 0;
    width: 100%;
    min-height: 370px;
    min-width: 740px;
  }

  .img-overlay {
    // linear gradient overlay for images
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.05)) repeat 0% 0%,
      transparent;
  }

  .container {
    min-height: 370px;
    max-height: 370px;
  }
}

// responsive styles for header images
@media screen and (max-width: 992px) {
  .context {
    img.img-header {
      top: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .context {
    img.img-header {
      top: 0;
    }
  }
}

@media screen and (max-width: 998px) {
  .list .card .card-img-overlay {
    padding: 0 30px;
  }

  .list .card .img-box {
    min-height: 200px !important;
    max-height: 200px !important;
  }
}

img.ng-lazyloaded {
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

div.carousel-background,
.ng-lazyloading {
  background-color: rgba(223, 224, 226, 1);
  transition: background-color 0.4s ease-in-out;
}

div.ng-lazyloaded {
  background-color: rgba(223, 224, 226, 0);
  transition: background-color 0.4s ease-in-out;
}

div.ng-lazyloaded:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  content: ' ';
}

// global print styles (hiding body, only displaing print-section)
@media print {
  #header {
    display: none;
  }

  #footer {
    display: none;
  }

  #cookieNotification {
    display: none;
  }

  .page-break-after {
    page-break-after: always;
  }

  .print-section {

    h3,
    .h3 {
      display: block;
      line-height: 1.5em;
    }

    small {
      display: block;
    }

    .card-header.row {
      padding-left: 0px;
      margin-left: 0px;
    }

    .card-block,
    .card-header,
    .card-footer {
      padding: 0px;
    }
  }

  .print-section.static-content {
    .content {
      visibility: visible;
    }

    .context {
      visibility: hidden !important;
      display: none;
    }

    .contact {
      visibility: hidden;
      display: none;
    }

    h2 {
      margin-top: 30px !important;
      margin-bottom: 20px !important;
    }
  }
}

// supporter logos
.supporter {
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.6;

  &:hover {
    -webkit-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1;
  }
}

// tooltip fix
.tooltip.in {
  opacity: 1;
}

// tooltip fix
.tooltip.in {
  opacity: 1;
}

@media screen and (max-width: 998px) {
  .btn-xs {
    font-size: 0.85rem;
    padding: 10px 25px !important;
    line-height: 1.2;
  }
}

@media screen and (max-width: 768px) {
  h2 {
    line-height: 1.1;
    font-size: 1.8em;
  }

  .carousel-arrows {
    .carousel-arrow-next {
      right: 15px !important;
    }

    .carousel-arrow-prev {
      left: 15px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .btn-group-fixed {
    top: 20%;

    button {
      width: 45px !important;
      height: 45px;
    }
  }
}

@media print {
  .container {
    max-width: 92% !important;
  }

  .page-break-before {
    page-break-before: always !important;
    margin-top: 20px;
  }

  .page-break-after {
    page-break-after: always;
  }

  .panel-collapse {
    display: block !important;
  }

  img,
  h1,
  h2,
  h3,
  li {
    page-break-inside: avoid !important;
  }
}

@page {
  margin: 1cm;
}

/* tooltip fix https://github.com/valor-software/ngx-bootstrap/issues/6535 */
.popover {
  position: absolute;
  top: 0;
  left: 0;
}

.popover-arrow {
  position: absolute;
}

.tooltip {
  position: absolute;
}

.tooltip-arrow {
  position: absolute;
}

#cmpcookieinfo {
  font-size: 80%;
  line-height: 1.2;
  opacity: 0.8;
}